import { connect } from 'react-redux'
import _ from 'lodash'
import { compose, withHandlers } from 'recompose'
import ReportsSearchFilters from './ReportsSearchFilters'
import { actions as reportsActions, selectors as reportsSelectors } from '../../../../store/modules/reports'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import { selectors as platformSelectors } from '../../../../store/modules/platforms'
import { translations } from '../../../../config'
import modalService from '../../../../services/modalService'
import { selectors as organisationDetailsSelectors } from "../../../../store/modules/organisationDetails"
import { push } from 'connected-react-router'

const mapStateToProps = state => {
  const loggedInUserStoresOptions = authSelectors.getUserOrganisationsOptions(state)
  const canSelectOrgs = authSelectors.getHasMultipleOrganisations(state)
  const filters = reportsSelectors.getFilters(state)
  const numberOfActiveFilters = reportsSelectors.getNumberOfActiveFilters(state)
  const results = reportsSelectors.getResults(state)
  const enableReportStages = platformSelectors.getHasEnabledReportStages(state)
  const locationProviderId = organisationDetailsSelectors.getLocationProviderId(state)
  const hasExportManagementEnabled = platformSelectors.getHasExportManagementEnabled(state)

  const { statusOptions, typesOptions, sourceOptions } = platformSelectors.getCurrentPlatformReportManagementFilters(state)
  const reportStages = enableReportStages ? {
    devicesReport: [
      { label: 'Initial', value: 'Initial' },
      { label: 'Initial Final', value: 'Combined initial and final' },
      { label: 'Follow Up', value: 'Follow up' },
      { label: 'Final Rep', value: 'Final (Reportable incident)' },
      { label: 'Final NonRep', value: 'Final (Non-reportable incident)' },
    ],
    fscaReport: [
      { label: 'Initial', value: 'Initial' },
      { label: 'Follow Up', value: 'Follow Up' },
      { label: 'Final', value: 'Final' },
    ],
    psrReport: [
      { label: 'Application for PSR', value: '1' },
      { label: 'Periodic analysis update', value: '2' },
      { label: 'Closure PSR', value: '3' }
    ],
    trendReport: [
      { label: 'Trend', value: '1' },
      { label: 'Trend: Follow up', value: '2' },
      { label: 'Trend: Final', value: '3' },
    ],
    saeReport: [
      { label: 'Quarterly report', value: '1' },
      { label: 'Annual report', value: '2' },
      { label: 'End of study report', value: '3' },
      { label: 'Deviation report', value: '4' },
      { label: 'Early termination report', value: '5' },
      { label: 'Other', value: '6' },
    ]
  } : null

  const translatedStatusOptions = _.map(statusOptions, statusOption => ({ ...statusOption, label: translations(`Report Management - Status ${statusOption.label}`) }))
  const translatedTypesOptions = _.map(typesOptions, typeOption => ({ ...typeOption, label: translations(typeOption.label) }))
  const translatedSourceOptions = _.map(sourceOptions, sourceOption => ({ ...sourceOption, label: translations(sourceOption.label) }))

  return {
    results,
    filters,
    numberOfActiveFilters,
    statusOptions: translatedStatusOptions,
    typesOptions: translatedTypesOptions,
    sourceOptions: translatedSourceOptions,
    reportStages,
    loggedInUserStoresOptions,
    canSelectOrgs,
    hasLocationProvider: !!locationProviderId,
    hasExportManagementEnabled
  }
}

export default compose(
  connect(mapStateToProps),
  withHandlers({
    updateFilter: ({ dispatch, filters }) => filter => {
      const newFilters = {
        ...filters,
        [filter.name]: filter.value
      }
      dispatch(reportsActions.changeFilters({ filters: newFilters }))
      dispatch(reportsActions.searchReportsFresh({ filters: newFilters }))
    },
    downloadReports: ({ dispatch, filters, results, hasExportManagementEnabled }) => () => {
      if (results.length && results.length > 0) {
        if(hasExportManagementEnabled){
          dispatch(reportsActions.exportFilteredReports({ filters, format: 'xml' })) // TODO: will update in MSS-4237 to have user choose PDF or XML formats
          modalService.action({
            title: translations('Report Management - View Exports Title'),
            text: translations(`Report Management - View Exports Info`),
            actions: [
              {
                text: translations('Cancel'),
                onClick: () => modalService.close(),
              },
              {
                text: translations('Report Management - View Exports'),
                onClick: () => {dispatch(push(`/export-management`))},
              }
            ]
          })
        }
        else{
          const ids = _.map(results, res => res.id)
          dispatch(reportsActions.fetchReportXMLForIds({ ids }))
        }
      } else {
        modalService.action({
          title: translations('No reports'),
          text: translations('There are no reports to export'),
          actions: [
            {
              text: translations('Continue')
            }
          ]
        })
      }
    }
  })
)(ReportsSearchFilters)
